/*******************************/
/********* General CSS *********/
/*******************************/
body {
  color: #666666;
  background: #dddddd;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

h1,
h2, 
h3, 
h4,
h5, 
h6 {
  color: #030f27;
}

a {
  color: #666666;
  transition: .3s;
}

a:hover,
a:active,
a:focus {
  color: #fdbe33;
  outline: none;
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

.btn {
  transition: .3s;
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/



/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/


/*******************************/
/******** Carousel CSS *********/
/*******************************/



/*******************************/
/******* Page Header CSS *******/
/*******************************/



/*******************************/
/******* Section Header ********/
/*******************************/



/*******************************/
/********* Feature CSS *********/
/*******************************/



/*******************************/
/********** About CSS **********/
/*******************************/


/*******************************/
/********** Fact CSS ***********/
/*******************************/



/*******************************/
/********* Service CSS *********/
/*******************************/



/*******************************/
/********** Video CSS **********/
/*******************************/



/*******************************/
/*********** Team CSS **********/
/*******************************/


/*******************************/
/*********** FAQs CSS **********/
/*******************************/



/*******************************/
/******* Testimonial CSS *******/
/*******************************/


/*******************************/
/*********** Blog CSS **********/
/*******************************/


/*******************************/
/******* Single Post CSS *******/
/*******************************/



/**********************************/
/*********** Sidebar CSS **********/
/**********************************/



/*******************************/
/******** Portfolio CSS ********/
/*******************************/



/*******************************/
/********* Contact CSS *********/
/*******************************/



/*******************************/
/********* Footer CSS **********/
/*******************************/

